import { PayloadAction, ToggleSidebar } from "../types/type";

interface State {
    isShowSideBar: boolean;
}

const initialState: State = {
    isShowSideBar: false
};

const CommonReducer = (state = initialState, action: PayloadAction): State => {
    switch (action.type) {
        case ToggleSidebar:
            return {
                ...state,
                isShowSideBar: !state.isShowSideBar
            };
        default:
            return state;
    }
};

export default CommonReducer;
