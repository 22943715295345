import { useState, useEffect, ReactElement, createContext } from "react";
import { RefreshContextProviderProps } from "./types";

const API_INTERVAL = 5000;
const FAST_INTERVAL = 5000;
const SLOW_INTERVAL = 60000;

const RefreshContext = createContext({ api: 0, slow: 0, fast: 0 });

const RefreshContextProvider = ({ children }: RefreshContextProviderProps): ReactElement => {
    const [api, setApi] = useState(0);
    const [slow, setSlow] = useState(0);
    const [fast, setFast] = useState(0);

    useEffect(() => {
        const interval = setInterval(async () => {
            setApi((prev) => prev + 1);
        }, API_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            setFast((prev) => prev + 1);
        }, FAST_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            setSlow((prev) => prev + 1);
        }, SLOW_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    return <RefreshContext.Provider value={{ api, slow, fast }}>{children}</RefreshContext.Provider>;
};

export { RefreshContext, RefreshContextProvider };
