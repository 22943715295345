import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./index.css";
// import { BrowserRouter } from "react-router-dom";
// import store from "./store";
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import Web3Provider from "./web3/Web3Provider";
import { MetaMaskInpageProvider } from "@metamask/providers";
import StoreConfigs from "./store/config";
import StoreContextProvider from "./context/StoreContext";

declare global {
    interface Window {
        ethereum?: MetaMaskInpageProvider;
        main: () => void;

        Image: {
            prototype: HTMLImageElement;
            new (): HTMLImageElement;
        };
    }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <StrictMode>
        <Web3Provider>
            <StoreContextProvider>
                <StoreConfigs />
            </StoreContextProvider>
        </Web3Provider>
    </StrictMode>
);

reportWebVitals();
