import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import storage from "redux-persist/lib/storage";
import CommonReducer from "./common";
import NFTAdminReducer from "./NFTAdmin";

import AuthReducer from "./persist";
import SessionReducer from "./session";

const sessionConfig = {
    key: "session",
    storage: sessionStorage
};

const persistConfig = {
    key: "NFTAdmin",
    storage
};

export default combineReducers({
    auth: AuthReducer,
    session: persistReducer(sessionConfig, SessionReducer),
    NFTAdmin: persistReducer(persistConfig, NFTAdminReducer),
    common: CommonReducer
});
