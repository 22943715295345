/* eslint-disable indent */
import { CollectionData, UserData, moderatorAccounts } from "../../types/be.interface";
import {
    EligibleCollection,
    GetCollection,
    GetUserDetails,
    Login,
    PayloadAction,
    ToggleLayout,
    UpdateUser,
    SetRecevingPerksStatus,
    GetModeratorAccounts,
    SetCurrentModeratorAccount
} from "../types/type";

interface State {
    userInfo: UserData | null;
    collection: CollectionData | null;
    eligibleCollection: Record<string, string> | null;
    layout: "hodler" | "admin";
    moderatorAccounts: moderatorAccounts[] | null;
    currentModeratorAccount: moderatorAccounts | null;
}

const initialState: State = {
    userInfo: null,
    collection: null,
    eligibleCollection: null,
    layout: "hodler",
    moderatorAccounts: null,
    currentModeratorAccount: null
};

const AuthReducer = (state = initialState, action: PayloadAction): State => {
    switch (action.type) {
        case Login:
            return {
                ...state,
                userInfo: action?.payload?.data
            };
        case UpdateUser:
            return {
                ...state,
                userInfo: {
                    ...state?.userInfo,
                    ...action?.payload?.data
                }
            };
        case GetCollection:
            return {
                ...state,
                collection: action?.payload?.data
            };
        case EligibleCollection:
            return {
                ...state,
                eligibleCollection: action?.payload
            };
        case GetUserDetails:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    ...action?.payload?.data
                }
            };

        case ToggleLayout:
            return {
                ...state,
                layout: action?.payload?.newLayout || state.layout === "hodler" ? "admin" : "hodler"
            };

        case SetRecevingPerksStatus:
            return {
                ...state,
                userInfo: state?.userInfo
                    ? {
                          ...state?.userInfo,
                          disablePerks: state?.userInfo?.disablePerks === false
                      }
                    : null
            };

        case GetModeratorAccounts:
            return {
                ...state,
                moderatorAccounts: action?.payload?.data
            };

        case SetCurrentModeratorAccount:
            return {
                ...state,
                currentModeratorAccount: action?.payload?.data
            };
        default:
            return state;
    }
};

export default AuthReducer;
