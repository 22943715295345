/* eslint-disable indent */
import { PerkListType } from "../../pages/NftAdmin/Autopilot/types";
import { PerkManagerListType } from "../../pages/NftAdmin/PerkManager/types";
import { ModeratorListType } from "../../pages/NftAdmin/Settings/types";
import { UserData } from "../../types/be.interface";
import {
    FINISH_GUIDE_TOUR,
    GET_MODERATOR_LIST_END,
    GET_MODERATOR_LIST_START,
    GET_PERKMANAGER_LIST_END,
    GET_PERKMANAGER_LIST_START,
    GET_PERK_LIST_END,
    GET_PERK_LIST_START,
    NFTAdminPayloadAction,
    NFT_ADMIN_LOGIN,
    NFT_ADMIN_LOGOUT,
    SET_NFT_ADMIN_DETAILS,
    UPDATE_NFT_ADMIN
} from "../types/type";

interface State {
    userInfo: UserData | null;
    perkList: PerkListType | null;
    perkManagerList: PerkManagerListType | null;
    moderatorList: ModeratorListType | null;
}

const initialState: State = {
    userInfo: null,
    perkList: null,
    perkManagerList: null,
    moderatorList: null
};

const NFTAdminReducer = (state = initialState, action: NFTAdminPayloadAction): State => {
    switch (action.type) {
        case NFT_ADMIN_LOGIN:
            return {
                ...state,
                userInfo: action?.payload?.data
            };
        case UPDATE_NFT_ADMIN:
            return {
                ...state,
                userInfo: {
                    ...state?.userInfo,
                    ...action?.payload?.data
                }
            };
        case NFT_ADMIN_LOGOUT:
            return initialState;

        case FINISH_GUIDE_TOUR:
            return {
                ...state,
                userInfo: state.userInfo
                    ? {
                          ...state?.userInfo,
                          guided: action?.payload?.data
                      }
                    : null
            };
        case SET_NFT_ADMIN_DETAILS:
            return {
                ...state,
                userInfo: state.userInfo
                    ? {
                          ...state?.userInfo,
                          ...action?.payload?.data
                      }
                    : null
            };

        case GET_PERK_LIST_START:
            return {
                ...state,
                perkList: state.perkList
                    ? {
                          ...state.perkList,
                          data: null
                      }
                    : null
            };
        case GET_PERK_LIST_END:
            return {
                ...state,
                perkList: action?.payload?.data
            };
        case GET_PERKMANAGER_LIST_START:
            return {
                ...state,
                perkManagerList: state.perkManagerList
                    ? {
                          ...state.perkManagerList,
                          data: null
                      }
                    : null
            };
        case GET_PERKMANAGER_LIST_END:
            return {
                ...state,
                perkManagerList: action?.payload?.data
            };
        case GET_MODERATOR_LIST_START:
            return {
                ...state,
                moderatorList: null
            };
        case GET_MODERATOR_LIST_END:
            return {
                ...state,
                moderatorList: action?.payload?.data
            };
        default:
            return state;
    }
};

export default NFTAdminReducer;
