/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectedConnector } from "@web3-react/injected-connector";
import Web3 from "web3";
const chainId = parseInt(process.env.REACT_APP_NETWORK_ID ?? "1", 10);

const injected = new InjectedConnector({ supportedChainIds: [chainId] });

export const ConnectorNames = {
    Injected: "MetaMask",
    WalletConnect: "walletconnect"
};

export const connectorLocalStorageKey = "connectorId";

export const connectorsByName = {
    [ConnectorNames.Injected]: injected
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLibrary = (provider: any): Web3 => {
    return provider;
};

export const setupNetwork = async (): Promise<any> => {
    const provider = window.ethereum;
    if (provider) {
        const chainId = parseInt(process.env.REACT_APP_NETWORK_ID ?? "1", 10);
        try {
            const chain = `chain_${chainId}` as keyof typeof NetworksData;
            await provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ ...NetworksData[chain] }]
            });
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    } else {
        console.error("Can't setup the Mainnet network on metamask because window.ethereum is undefined");
        return false;
    }
};

const NetworksData = {
    chain_1: {
        chainId: "0x1"
    },
    chain_4: {
        chainId: "0x4"
    }
};

export const walletsMsgParams = (Address: string | null | undefined): string => {
    return JSON.stringify({
        types: {
            EIP712Domain: [{ name: "name", type: "string" }],
            User: [
                { name: "Url", type: "string" },
                { name: "Address", type: "string" }
            ]
        },
        primaryType: "User",
        domain: {
            name: "Hodlperks"
        },
        message: {
            Url: "hodlperks.com",
            Address
        }
    });
};
