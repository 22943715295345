import { PayloadAction, SET_COINBASE_ADDRESS, SET_METAMASK_ADDRESS, SET_WALLET_CONNECT_ADDRESS } from "../types/type";

interface State {
    metamaskAddress: string | null;
    walletConnectAddress: string | null;
    coinbaseAddress: string | null;
}

const initialState: State = {
    metamaskAddress: "",
    walletConnectAddress: "",
    coinbaseAddress: ""
};

const SessionReducer = (state = initialState, action: PayloadAction): State => {
    switch (action.type) {
        case SET_WALLET_CONNECT_ADDRESS:
            return {
                ...state,
                walletConnectAddress: action?.payload?.data
            };
        case SET_METAMASK_ADDRESS:
            return {
                ...state,
                metamaskAddress: action?.payload?.data
            };
        case SET_COINBASE_ADDRESS:
            return {
                ...state,
                coinbaseAddress: action?.payload?.data
            };
        default:
            return state;
    }
};

export default SessionReducer;
