/* eslint-disable @typescript-eslint/no-explicit-any */
import { Web3ReactProvider } from "@web3-react/core";
// import { getLibrary } from "./utils/web3React";
// import { RefreshContextProvider } from "./context/RefreshContext";
import { getLibrary } from "./utils";
import { RefreshContextProvider } from "./RefreshContextProvider";
import { RefreshContextProviderProps } from "./types";

const Providers: React.FC<RefreshContextProviderProps> = ({ children }) => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <RefreshContextProvider>{children}</RefreshContextProvider>
        </Web3ReactProvider>
    );
};

export default Providers;
