/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState } from "react";
import sessionStorage from "redux-persist/lib/storage/session";
import storage from "redux-persist/lib/storage";
import { STORAGE_TYPE } from "./types";

export const StoreContext = createContext<any>(null);

const StoreContextProvider = ({ children }: any) => {
    const [storeConfig, setStoreConfig] = useState({ storage: localStorage.getItem("persist:root") ? storage : sessionStorage });
    const [storeType, setStoreType] = useState<STORAGE_TYPE>(
        localStorage.getItem("persist:root") ? STORAGE_TYPE.LOCAL : STORAGE_TYPE.SESSION
    );
    const changeStoreConfig = (config: any) => {
        setStoreConfig(config);
    };

    const changeStorageType = (type: STORAGE_TYPE) => {
        setStoreType(type);
        if (STORAGE_TYPE.LOCAL === type) {
            sessionStorage.removeItem("persist:root");
            changeStoreConfig({ ...storeConfig, storage });
        } else if (STORAGE_TYPE.SESSION === type) {
            localStorage.removeItem("persist:root");
            changeStoreConfig({ ...storeConfig, storage: sessionStorage });
        }
    };

    return (
        <StoreContext.Provider
            value={{
                storeConfig,
                storeType,
                changeStoreConfig,
                changeStorageType
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export default StoreContextProvider;
