import { lazy, ReactElement, useContext } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { storeRef } from "..";
import { StoreContext } from "../../context/StoreContext";
const App = lazy(() => import("../../App"));

const StoreConfigs = (): ReactElement => {
    const { storeConfig } = useContext(StoreContext);
    store(storeConfig);

    return (
        <Provider store={storeRef?.current?.store}>
            <PersistGate loading={null} persistor={storeRef?.current?.persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
};

export default StoreConfigs;
