/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import { createRef } from "react";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

export type IRootState = ReturnType<typeof rootReducer>;
export const storeRef: any = createRef();
export default (config: any): void => {
    const persistConfig = {
        key: "root",
        whitelist: ["auth"],
        blacklist: ["session"],
        ...config
    };

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const middleware = [thunk];

    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));

    const persistor = persistStore(store);

    storeRef.current = { store, persistor };
};
