export const Login = "Login";
export const UpdateUser = "UpdateUser";
export const GetUserDetails = "GetUserDetails";
export const GetCollection = "GetCollection";
export const SET_METAMASK_ADDRESS = "SET_METAMASK_ADDRESS";
export const SET_COINBASE_ADDRESS = "SET_COINBASE_ADDRESS";
export const SET_WALLET_CONNECT_ADDRESS = "SET_WALLET_CONNECT_ADDRESS";
export const EligibleCollection = "EligibleCollection";
export const ToggleLayout = "ToggleLayout";
export const SetRecevingPerksStatus = "SetRecevingPerksStatus";
export const ToggleSidebar = "ToggleSidebar";
export const SetModeratorAccounts = "SetModeratorAccounts";
export const GetModeratorAccounts = "GetModeratorAccounts";
export const SetCurrentModeratorAccount = "SetCurrentModeratorAccount";

// NFTAdmin
export const NFT_ADMIN_LOGIN = "NFT_ADMIN_LOGIN";
export const NFT_ADMIN_LOGOUT = "NFT_ADMIN_LOGOUT";
export const FINISH_GUIDE_TOUR = "FINISH_GUIDE_TOUR";
export const SET_NFT_ADMIN_DETAILS = "SET_NFT_ADMIN_DETAILS";
export const UPDATE_NFT_ADMIN = "UPDATE_NFT_ADMIN";

// perk-list
export const GET_PERK_LIST_START = "GET_PERK_LIST_START";
export const GET_PERK_LIST_END = "GET_PERK_LIST_END";
// perk-list
export const GET_PERKMANAGER_LIST_START = "GET_PERKMANAGER_LIST_START";
export const GET_PERKMANAGER_LIST_END = "GET_PERKMANAGER_LIST_END";

// moderator-list
export const GET_MODERATOR_LIST_START = "GET_MODERATOR_LIST_START";
export const GET_MODERATOR_LIST_END = "GET_MODERATOR_LIST_END";

export type PayloadAction = {
    type:
        | typeof Login
        | typeof UpdateUser
        | typeof GetUserDetails
        | typeof GetCollection
        | typeof EligibleCollection
        | typeof SET_METAMASK_ADDRESS
        | typeof SET_COINBASE_ADDRESS
        | typeof SET_WALLET_CONNECT_ADDRESS
        | typeof SetRecevingPerksStatus
        | typeof SetRecevingPerksStatus
        | typeof ToggleLayout
        | typeof ToggleSidebar
        | typeof SetModeratorAccounts
        | typeof GetModeratorAccounts
        | typeof SetCurrentModeratorAccount;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Record<string, any>;
};

export type NFTAdminPayloadAction = {
    type:
        | typeof NFT_ADMIN_LOGIN
        | typeof NFT_ADMIN_LOGOUT
        | typeof FINISH_GUIDE_TOUR
        | typeof GET_PERK_LIST_END
        | typeof GET_PERK_LIST_START
        | typeof GET_PERKMANAGER_LIST_START
        | typeof GET_PERKMANAGER_LIST_END
        | typeof GET_MODERATOR_LIST_END
        | typeof GET_MODERATOR_LIST_START
        | typeof SET_NFT_ADMIN_DETAILS
        | typeof UPDATE_NFT_ADMIN;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Record<string, any>;
};
